<template>
  <div class="wrapper">
    <div class="main-content">
      <section>
        <div class="container">
          <div class="row">
            <h1>{{ hyperParams.title }}</h1>
            <p>{{ hyperParams.description }}</p>
            <h2>Hyper Parameters List</h2>
            <div class="d-flex justify-content-center align-items-center">
              <div class="row">
                <div
                  v-for="(hyperParam, index) in hyperParams.hyperParams"
                  :key="index"
                  class="col-md-12 mb-4 pattern-card"
                >
                  <div class="card zoom-card" @mouseenter="zoomIn" @mouseleave="zoomOut">
                    <div class="card-body">
                      <h3 class="card-title">{{ hyperParam.name }}</h3>
                      <p class="card-text">{{ hyperParam.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'HyperParams',
    data() {
      return {
        hyperParams: {
          title: "Fine-Tune Your AI Agent with Hyperparameter Optimization",
          description:
            "Enhance the performance of your AI agent by customizing its hyperparameters. Fine-tune critical settings to optimize learning rates, batch sizes, network architectures, and more.",
          hyperParams: [
            {
              key: "enableSuccessPercentage",
              name: "Enable Success Percentage",
              description:
                "Enables the tracking of the agent's success percentage in its decision-making process. When enabled, the agent considers its historical success rate when making decisions."
            },
            {
              key: "boolean",
              name: "Boolean Rewards",
              description:
                "When enabled, the rewards given by the agent are binary, where 1 represents a positive outcome and 0 represents a negative or neutral outcome. Useful for creating a simplified reward structure."
            },
            {
              key: "cappedReward",
              name: "Capped Rewards",
              description:
                "When enabled, limits the rewards given by the agent within a specified threshold. Rewards exceeding this threshold are capped at the threshold value, providing a more controlled reward structure."
            },
            {
              key: "targetMultiplier",
              name: "Target Multiplier",
              description:
                "A hyper-parameter that influences the agent's target values. It scales the target values used in training, affecting the learning process and target predictions."
            },
            {
              key: "enableNormalization",
              name: "Enable State Normalization",
              description:
                "Enables the normalization of actions after training. State normalization scales state values to a common range, aiding in faster and more stable learning."
            },
            {
              key: "normalizeEachAction",
              name: "Normalize Each Action",
              description:
                "When enabled, normalizes each action individually after training. This allows the agent to adapt to variations in different actions more effectively."
            },
            {
              key: "enableShortPositions",
              name: "Enable Short Positions",
              description:
                "When enabled, allows the agent to take short positions, enabling it to profit from declining asset prices. Useful for more complex trading scenarios."
            },
            {
              key: "openPositions",
              name: "Open Positions",
              description:
                "A list that stores information about open trading positions held by the agent. Tracks the agent's currently active positions in the market."
            },
            {
              key: "paramsRanges",
              name: "Hyper-parameter Ranges",
              description:
                "Defines the ranges for various hyper-parameters used by the agent, such as learning rate, discount factor, minimum reward threshold, epsilon, and target multiplier. These ranges influence the agent's learning process and behavior."
            },
            {
              key: "episodes",
              name: "Episodes",
              description:
                "The number of allowed retries for each created agent in params range."
            }
          ]
        }
      };
            },
            created() {
    // Hook created - Code exécuté lors de la création du composant
    // Définir le titre de la page lors de la création du composant
    document.title = this.hyperParams.title;
  },
            methods: {
            zoomIn(event) {
            event.currentTarget.classList.add('zoomed');
            },
            zoomOut(event) {
            event.currentTarget.classList.remove('zoomed');
            },
        },
  };
   </script>
  
  
  <style scoped>
  .pattern-card {
    background-color: #ffc700;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin: 1em;
    padding: 1em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    width: calc(100% - 2em);
    margin: 1em auto;
  }

  .pattern-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  .pattern-card h3 {
    font-size: 1.6rem;
    color: #343a40;
    margin-bottom: 0.5em;
  }

  .pattern-card p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
/*
  @media screen and (max-width: 48em) {
    .pattern-card {
      width: calc(100% - 2em);
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: medium;
    }
    .pattern-card h3 {
      font-size: 1.2em;
    }
    .pattern-card p {
      font-size: 1em;
    }
  }

  @media screen and (min-width: 48em) and (max-width: 62em) {
    .pattern-card {
      width: calc(48% - 2em);
    }
  }

  @media screen and (min-width: 62em) {
    .pattern-card {
      width: calc(30% - 2em);
    }
  }*/
</style>
